:root {
    --primary_color: #003060;
    --secondary_color: #6eb43f;
    --tertiary_color: #90C2E7;
    --success_color: #43a605;
    --warning_color: #d67b0b;
    --danger_color: #E21C1C;
    --primary_color_fade: rgba(0,48,96, 0.2);
    --secondary_color_fade: rgba(144,194,231, 0.2);
    --tertiary_color_fade: rgba(206,211,220, 0.2);
    --success_color_fade: rgba(15,182,11,0.2);
    --warning_color_fade: rgba(247,127,0, 0.2);
    --danger_color_fade: rgba(226,28,28, 0.2);
  }

/* SIDE BAR */
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    background: #fff;
    color: var(--primary_color);
    border-right: 1px solid var(--primary_color);
    min-height: 100vh;
    width: 300px;
    transition: all 0.5s;
}
.top_section{
    display: flex;
    align-items: center;
    padding: 10px 15px;
}
.logo{
    font-size: 30px;
    margin-bottom: 20px;
}

.bars{
    display: flex;
    font-size: 25px;
    margin-left: 120px;
    cursor: pointer;
}

.bars:hover {
    color: var(--secondary_color);
}

.link{
    display: flex;
    color: var(--primary_color);
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
}

.link:hover{
    background: var(--secondary_color);
    color: #fff;
    transition: all 0.5s;
}

.link.logout {
    background-color: var(--danger_color);
    color: #fff;
}

.link.logout:hover {
    background-color: var(--secondary_color);
    cursor: pointer !important;
}

.active{
    background: var(--secondary_color);
    color: #fff;
}
.icon, .link_text {
    font-size: 18px;
    font-weight: 300;
}