:root {
  --primary_color: #003060;
  --secondary_color: #6eb43f;
  --tertiary_color: #90C2E7;
  --success_color: #43a605;
  --warning_color: #d67b0b;
  --danger_color: #E21C1C;
  --primary_color_fade: rgba(0,48,96, 0.2);
  --secondary_color_fade: rgba(144,194,231, 0.2);
  --tertiary_color_fade: rgba(206,211,220, 0.2);
  --success_color_fade: rgba(15,182,11,0.2);
  --warning_color_fade: rgba(247,127,0, 0.2);
  --danger_color_fade: rgba(226,28,28, 0.2);
}

.app-container {
  display: flex;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif;
}

.border-top-black-4 {
  border-top: 4px solid black;
}

.border {
  border: 1px solid var(--secondary_color) !important;
}

.rounded {
  border-radius: 5px;
}

.text-light {
  font-weight: 300;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.display-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.main-container {
  margin-top: 30px;
}

.content-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#login-container {
  text-align: center;
  border: 1px solid var(--primary_color);
  border-radius: 5px;
  padding: 30px 10px;
  width: 50%;
}

.p1 {
  padding: 10px;
}

.p2 {
  padding: 20px;
}

.centered {
  text-align: center;
}

.sticky {
  position: sticky;
}

.pointer {
  cursor: pointer;
}

.text-right {
  text-align: end;
}

.primary {
	color: var(--primary_color);
}

.secondary {
	color: var(--secondary_color);
}

.btn-primary {
  background-color: var(--primary_color);
  color: #fff;
  border: 1px solid var(--primary_color);
  border-radius: 5px;
  padding: 5px 20px;
}
.btn-primary:hover {
  background-color: var(--secondary_color);
  border: 1px solid var(--secondary_color);
}

.btn-secondary {
  background-color: var(--secondary_color);
  color: #fff;
  border: 1px solid var(--secondary_color);
  padding: 14px 20px;
}
.btn-secondary:hover {
  background-color: #fff;
  color: var(--secondary_color);
}

.btn-success {
  background-color: var(--success_color);
  color: #fff;
  border: 1px solid var(--success_color);
  border-radius: 5px;
  padding: 5px 20px;
}
.btn-success:hover {
  background-color: var(--tertiary_color);
  border: 1px solid var(--tertiary_color);
  color: var(--primary_color);
}

.justify-center {
	justify-content: center;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-4 {
  margin-top: 40px !important;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-4 {
  margin-bottom: 40px;
}

.loading-modal-body {
  color: var(--primary_color);
  height: 250px;
  text-align: center;
}

@media only screen and (max-width: 980px) {
  #login-container {
    width: 100%;
  }
}

/* DATATABLE OVERRIDE STYLES */
.ButtonGroup__root button {
  background-color: transparent !important;
  color: var(--primary_color);
  border: 0 !important;
}

.ButtonGroup__root button:hover {
  background-color: transparent !important;
  color: var(--secondary_color);
}

.ButtonGroup__root button:disabled {
  background-color: transparent !important;
  color: black;
}

div.datatable-filter > div.input-group button {
  background-color: var(--primary_color);
  border: 1px solid var(--primary_color);
}